import React, { Component } from "react";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="about" style={{paddingTop:'5%'}}>
          <div  >
            <div style={{marginBottom:'10px'}}>
              <h2 className="text-center">About Me</h2>
            </div>
            <div className="row">
              <div className="three columns">
                <h4 style={{ color: "#ffffff" }}>How Expo works ?</h4>
              </div>
              <div className="nine columns">
                <span>
                  Expodata, taking into account the specific requirements of
                  each expo and dedicated to the needs of each event. Expodata’s
                  IT team creates solutions that fit each organization, ensuring
                  the perfectly organized attendance of visitors and exhibitors,
                  connecting them immediately and creating and managing a
                  database - with Whatsapp Marketing tools for each exhibitor.
                  The process begins with online registration​​, where, via
                  specially created online forms ​with whatsapp integration. The
                  exhibit​or ​will at all times have access to the online,
                  real-time statistics of the event, where a complete ​data​ of
                  traffic is recorded, with figures such as hourly​/daily​
                  attendance etc.
                </span>
              </div>
            </div>
            <div className="row" style={{marginTop:'40px'}}>
              <div className="three columns">
                <h4 style={{ color: "#ffffff" }}>Use Of Expodata, How Expo Helpful ? </h4>
              </div>
              <div className="nine columns">
                <ol style={{marginLeft:'20px'}}>
                  <li>Save Quickly:- To Save Multiple Contacts</li>
                  <li>Used For Future Promotion:- You Can Use Expodata App To Share Your Contact Instantly And Grow Your Network</li>
                  <li>Easy To Download:- People Can Scan QR Code And Reach To Your Business</li>
                  <li>Keep Record For Lifetime:- Visitors get Connect Easily Using Expodata App</li>
                  <li>Send Out WA Message To All:- To Save Multiple Contacts</li>
                  <li>Easy To Know Your Business:- Connect Easily Using Whatsapp Scanner</li>
                  <li>Save Visitor's Records Digitally</li>
                </ol>
              </div>
            </div>
            <div className="row" style={{marginTop:'40px'}}>
              <div className="three columns">
                <h4 style={{ color: "#ffffff" }}>Expodata Support System</h4>
              </div>
              <div className="nine columns">
                <ol style={{marginLeft:'20px'}}>
                  <li>Expodata application With Whatsapp Integration.</li>
                  <li>Save Safely Your Data.</li>
                  <li>Receiving Auto Whatsapp Message For Multiple Contacts.</li>
                  <li>Digitally Grow Your Network.</li>
                  <li>Update Real-Time Business Information.</li>
                </ol>
              </div>
            </div>
          </div>
      </section>
    );
  }
}

export default About;
