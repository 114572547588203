import React, { Component } from "react";
import { Link } from "react-router-dom";
import Background from "./images/expobg.jpg";

class Register extends Component {
  render() {
    return (
      <section id="portfolio" style={{ backgroundImage: `url(${Background})` }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ backgroundColor: "#d4d4d4", padding: "60px",borderRadius:'10px' }}>
            <div style={{ marginBottom: "40px" }}>
              <h4>Welcome to Register</h4>
            </div>
            <div>
              <input
                type="text"
                placeholder="Business name"
                style={{
                  borderRadius: "8px",
                  marginBottom: "20px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Mobile number"
                style={{
                  borderRadius: "8px",
                  marginBottom: "20px",
                  height: "30px",
                }}
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Address"
                style={{
                  borderRadius: "8px",
                  marginBottom: "20px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                style={{
                  borderRadius: "8px",
                  marginBottom: "20px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                style={{
                  marginBottom: "32px",
                  borderRadius: "8px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <button
                style={{
                  borderRadius: "8px",
                  width: "100%",

                  height: "30px",
                  padding: 0,
                }}
              >
                Register
              </button>
            </div>
            <span style={{ textAlign: "center" }}>Already have account?</span>
            <span>
              <Link to="/">Login</Link>
            </span>
          </div>
        </div>
      </section>
    );
  }
}

export default Register;
