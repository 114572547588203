import React, { Component } from "react";
import "./FooterCSS.css";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    // const networks = this.props.data.social.map(function (network) {
    //   return (
    //     <li key={network.name}>
    //       <a href={network.url}>
    //         <i className={network.className}></i>
    //       </a>
    //     </li>
    //   );
    // });

    return (
      <footer>
        <div className="row">
          <div className="twelve columns">
            {/* <ul className="social-links">{networks}</ul> */}

            <ul className="copyright" style={{ color: "#ffffff" }}>
              <li>&copy; Copyright 2023 EXPODATA. All Rights Reserved</li>
              <li>
                Design by{" "}
                <a
                  style={{ color: "#42d1f5" }}
                  title="Styleshout"
                  href="http://www.rslsolution.com/"
                >
                  RSL Solution Pvt.Ltd.
                </a>
              </li>
            </ul>
          </div>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
