import React, { Component } from "react";

class Contact extends Component {
  render() {

    return (
      <section id="contact" style={{ backgroundColor: "#ffffff" }}>
        <div className="row">
          <div className="seven columns">
            <h1
              style={{
                color: "#000000",
                textAlign: "center",
                marginBottom: "60px",
              }}
            >
              Contact Form
            </h1>
            <div
              style={{
                backgroundColor: "#d4d4d4",
                borderRadius: 10,
                padding: "40px",
                display:'flex',
                justifyContent:'center',
                flexDirection:'column'

              }}
            >
              <div>
                <label
                  htmlFor="contactName"
                  style={{ color: "#000000", paddingLeft: "10px" }}
                >
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  id="contactName"
                  name="contactName"
                  onChange={this.handleChange}
                  style={{
                    borderRadius: "8px",
                    width:"100%",
                    marginBottom:0,
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="contactEmail"
                  style={{ color: "#000000", paddingLeft: "10px" }}
                >
                  Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  id="contactEmail"
                  name="contactEmail"
                  onChange={this.handleChange}
                  style={{
                    borderRadius: "8px",
                    width:"100%",
                    marginBottom:0,
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="contactSubject"
                  style={{ color: "#000000", paddingLeft: "10px" }}
                >
                  Subject
                </label>
                <input
                  type="text"
                  defaultValue=""
                  id="contactSubject"
                  name="contactSubject"
                  onChange={this.handleChange}
                  style={{
                    borderRadius: "8px",
                    width:"100%",
                    marginBottom:0,
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="contactMessage"
                  style={{ color: "#000000", paddingLeft: "10px" }}
                >
                  Message <span className="required">*</span>
                </label>
                <textarea
                  id="contactMessage"
                  name="contactMessage"
                  style={{
                    borderRadius: "8px",
                    width:"100%",
                    marginBottom:0,
                  }}
                ></textarea>
              </div>

              <div style={{display:'flex',justifyContent:'center'}}>
                <button
                  className="submit"
                  style={{
                    borderRadius: "8px",
                  }}
                >
                  Submit
                </button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: "100px", }}>
            <h4
              style={{
                color: "#000000",
                textAlign:'center'
              }}
            >
              Address and Phone
            </h4>
            <p className="address">
              Near, Sayli Complex Rd, Walhekarwadi, Sector No. 32,
              Chinchwad, Pune, Maharashtra 411033.
              <br />
               +91 7887871415
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
