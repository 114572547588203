import React, { Component } from "react";
import Logo from "./images/Logo.png";

class Header extends Component {
  
  render() {
    
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const description = this.props.data.description;

    return (
      <header id="home" style={{ backgroundColor: "#ffffff", height: "570px" }}>
        <nav id="nav-wrap" style={{ backgroundColor: "#0d114a" }}>
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            {/* <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li> */}

            <li>
              <a className="smoothscroll" href="#portfolio">
                Login
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <img src={Logo} width="250px" height="250px" />
              <h1 className="responsive" style={{ color: "#000000" }}>
                {name}
              </h1>
              {/* style={{ color: "#000000", backgroundColor:'#ffffff'}} */}
              <h3 className="responsive" style={{ color: "#000000" }}>
                {description}.
              </h3>
              {/* style={{ color: "#000000", backgroundColor:'#ffffff'}} */}
            {/* <hr /> */}
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
