import React, { Component } from "react";
import Background from "./images/expobg.jpg";
import { Link } from "react-router-dom";

class Resume extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
    };
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  signin = async () => {
    const formData = {
      email: this.state.email,
      password: this.state.password,
    };
    await fetch("https://expodata.in/login.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.result === "success") {
          window.location.replace(
            `https://expodata.in/admin/login.php?email=${this.state.email}&password=${this.state.password}`
          );
        } else {
          alert("Invalid login details.");
        }
        this.setState({ email: "" });
        this.setState({ password: "" });
      })
      .catch((error) => console.log(error));
  };

  render() {
    return (
      <section
        id="portfolio"
        style={{ backgroundImage: `url(${Background})`, height: "650px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "65px",
          }}
        >
          <div
            style={{
              padding: "55px",
              borderRadius: 10,
              backgroundColor: "#d4d4d4",
            }}
          >
            <div style={{ marginBottom: "40px" }}>
              <h4>Welcome to login</h4>
            </div>
            <div>
              <input
                type="text"
                placeholder="Login"
                value={this.state.email}
                onChange={(e) => {
                  this.handleEmail(e);
                }}
                style={{
                  borderRadius: "8px",
                  marginBottom: "20px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => {
                  this.handlePassword(e);
                }}
                style={{
                  marginBottom: "32px",
                  borderRadius: "8px",
                  height: "30px",
                }}
              />
            </div>
            <div>
              <button
                onClick={() =>
                  // window.location.replace(
                  //   `https://expodata.in/admin/login.php?email=${this.state.email}&password=${this.state.password}`
                  // )
                  this.signin()
                }
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "30px",
                  padding: 0,
                }}
              >
                Login
              </button>
            </div>
            <span style={{ textAlign: "center" }}>Create new account? </span>
            <span>
              <Link to="register">Register</Link>
            </span>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
